define('debd-2016-frontend/components/page-contact-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      sendMessage: function sendMessage() {
        var self = this;

        $.ajax({
          type: 'POST',
          url: 'https://backend.debd.com/wp-json/wp/v2/contact-form',
          data: {
            name: self.get('name') || 'Kein Name angegeben',
            email: self.get('email') || 'Keine E-Mail-Adresse angegeben',
            interest: self.get('interest') || 'Kein Interesse angegeben',
            message: self.get('message') || 'Keine Nachricht angegeben'
          },
          success: function success() {
            self.set('name', null);
            self.set('email', null);
            self.set('interest', null);
            self.set('message', null);

            self.$().find('.page-contact-form').addClass('message-sent');
          }
        });
      }
    }
  });
});
/* global $ */