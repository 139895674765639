define('debd-2016-frontend/components/project-vimeo-video', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    guid: null,
    video: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('guid', _ember['default'].guidFor(this));

      this.set('video', this.$().find('.vimeo-iframe'));
      this.get('video').data('aspectRatio', this.get('video').height() / this.get('video').width()).removeAttr('height').removeAttr('width');

      _ember['default'].$(window).on('resize.' + this.get('guid'), function () {
        _this._scaleVideo.apply(_this);
      }).trigger('resize.' + this.get('guid'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).off('resize.' + this.get('guid'));
    },

    _scaleVideo: function _scaleVideo() {
      var $el = this.get('video');
      var newWidth = $el.closest('.video').width();

      $el.width(newWidth).height(newWidth * $el.data('aspectRatio'));
    }
  });
});