define("debd-2016-frontend/templates/components/project-vimeo-video", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/project-vimeo-video.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "video");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("iframe");
          dom.setAttribute(el2, "class", "vimeo-iframe");
          dom.setAttribute(el2, "width", "640");
          dom.setAttribute(el2, "height", "360");
          dom.setAttribute(el2, "frameborder", "0");
          dom.setAttribute(el2, "webkitallowfullscreen", "");
          dom.setAttribute(el2, "mozallowfullscreen", "");
          dom.setAttribute(el2, "allowfullscreen", "");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", ["https://player.vimeo.com/video/", ["get", "content.video-id-2", ["loc", [null, [7, 59], [7, 77]]]]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "debd-2016-frontend/templates/components/project-vimeo-video.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "video");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("iframe");
        dom.setAttribute(el3, "class", "vimeo-iframe");
        dom.setAttribute(el3, "width", "640");
        dom.setAttribute(el3, "height", "360");
        dom.setAttribute(el3, "frameborder", "0");
        dom.setAttribute(el3, "webkitallowfullscreen", "");
        dom.setAttribute(el3, "mozallowfullscreen", "");
        dom.setAttribute(el3, "allowfullscreen", "");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createAttrMorph(element2, 'src');
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["project-vimeo-video ", ["subexpr", "if", [["subexpr", "eq", [["get", "content.video-layout", ["loc", [null, [1, 41], [1, 61]]]], "two"], [], ["loc", [null, [1, 37], [1, 68]]]], "videos"], [], ["loc", [null, [1, 32], [1, 79]]]]]]], ["attribute", "src", ["concat", ["https://player.vimeo.com/video/", ["get", "content.video-id", ["loc", [null, [3, 55], [3, 71]]]]]]], ["block", "if", [["subexpr", "eq", [["get", "content.video-layout", ["loc", [null, [5, 14], [5, 34]]]], "two"], [], ["loc", [null, [5, 10], [5, 41]]]]], [], 0, null, ["loc", [null, [5, 4], [9, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});