define('debd-2016-frontend/components/page-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    _guid: null,
    _pageHeaderHeight: 0,
    _pageHeaderHeadline: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].$('.background-video').removeClass('.loaded').each(function (i, el) {
        _ember['default'].$(el).on('loadedmetadata', function () {
          _ember['default'].$(el).addClass('loaded');
        });
      });

      this._guid = _ember['default'].guidFor(this);
      this._pageHeaderHeight = this.$().height();
      this._pageHeaderHeadline = this.$().find('h1').get(0);

      _ember['default'].$(window).on('scroll.' + this._guid, function () {
        requestAnimationFrame(function () {
          _this._parallax.apply(_this);
        });
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('.background-video').each(function (i, el) {
        _ember['default'].$(el).off('onloadedmetadata');
      });

      this._pageHeaderHeight = 0;
      this._pageHeaderHeadline = null;

      _ember['default'].$(window).off('scroll.' + this._guid);
    },

    _parallax: function _parallax() {
      var windowScrollTop = _ember['default'].$(window).scrollTop();
      var parallax = Math.round(windowScrollTop * 0.4);
      var opacity = Math.round((1 - windowScrollTop / this._pageHeaderHeight) * 100) / 100;

      this._pageHeaderHeadline.style.opacity = opacity;
      this._pageHeaderHeadline.style.transform = 'translate3d(0,' + parallax + 'px,0)';
    }
  });
});