define("debd-2016-frontend/templates/head", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 50
          }
        },
        "moduleName": "debd-2016-frontend/templates/head.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "name", "description");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:title");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:image");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var element2 = dom.childAt(fragment, [6]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createAttrMorph(element0, 'content');
        morphs[2] = dom.createAttrMorph(element1, 'content');
        morphs[3] = dom.createAttrMorph(element2, 'content');
        return morphs;
      },
      statements: [["content", "model.title", ["loc", [null, [1, 7], [1, 22]]]], ["attribute", "content", ["get", "model.description", ["loc", [null, [2, 35], [2, 52]]]]], ["attribute", "content", ["get", "model.title", ["loc", [null, [4, 36], [4, 47]]]]], ["attribute", "content", ["get", "model.image", ["loc", [null, [5, 36], [5, 47]]]]]],
      locals: [],
      templates: []
    };
  })());
});