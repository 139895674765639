define('debd-2016-frontend/helpers/background-style', ['exports', 'ember'], function (exports, _ember) {
  exports.backgroundStyle = backgroundStyle;

  function backgroundStyle(params, hash) {
    var style;

    if (hash.content.background === 'color') {
      style = 'background-color: ' + hash.content['background-content'] + ';';
    } else if (hash.content.background === 'image') {
      style = 'background-image: url(\'' + hash.content['background-content'] + '\');';
    } else if (hash.content.background === 'video') {
      style = 'background-color: ' + hash.content['background-video-background-color'] + ';';
    }

    if (style) {
      style = _ember['default'].String.htmlSafe(style);
    }

    return style;
  }

  exports['default'] = _ember['default'].Helper.helper(backgroundStyle);
});