define('debd-2016-frontend/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].$('body').addClass('loaded');

      if ('objectFit' in document.documentElement.style === false) {
        _ember['default'].$('html').addClass('no-object-fit');
      }
    }
  });
});