define('debd-2016-frontend/components/page-google-maps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    map: null,
    marker: null,
    markerListener: null,
    markerContent: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;

      var position = {
        lat: parseFloat(this.get('content').latitude),
        lng: parseFloat(this.get('content').longitude)
      };

      this.markerContent = this.get('content').marker;

      this.map = new google.maps.Map(document.getElementById('google-map'), {
        center: position,
        zoom: parseInt(this.get('content').zoom),
        scrollwheel: false
      });

      this.marker = new google.maps.Marker({
        position: position,
        map: this.map
      });

      this.markerListener = this.marker.addListener('click', function () {
        self._openInfoWindow(self);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.markerListener.remove();
    },

    _openInfoWindow: function _openInfoWindow(self) {
      var infowindow = new google.maps.InfoWindow({
        content: self.markerContent
      });

      infowindow.open(self.map, self.marker);
    }
  });
});
/* global google */