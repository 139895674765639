define('debd-2016-frontend/routes/projects', ['exports', 'ember', 'debd-2016-frontend/mixins/page-transition', 'debd-2016-frontend/config/environment'], function (exports, _ember, _debd2016FrontendMixinsPageTransition, _debd2016FrontendConfigEnvironment) {
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend(_debd2016FrontendMixinsPageTransition['default'], {
    headData: _ember['default'].inject.service(),

    model: function model() {
      return this.store.find('page', 370);
    },

    afterModel: function afterModel(model) {
      set(this, 'headData.title', model.get('title') + _debd2016FrontendConfigEnvironment['default'].basicPageTitle);
      set(this, 'headData.description', model.get('acf.page-description'));
      set(this, 'headData.image', model.get('acf.page-image'));
    },

    actions: {
      error: function error(_error) {
        if (_error) {
          return this.transitionTo('error');
        }
      }
    }
  });
});