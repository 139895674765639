define('debd-2016-frontend/components/page-team', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    guid: null,
    employees: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('guid', _ember['default'].guidFor(this));

      this.set('employees', this.$().find('.employees').find('img'));

      _ember['default'].$(window).on('resize.' + this.get('guid'), function () {
        _ember['default'].run.debounce(_this, function () {
          _this._rescaleImages.apply(_this);
        }, 250);
      });

      var self = this;
      this.get('employees').imagesLoaded(function () {
        self._rescaleImages.apply(self);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).off('resize.' + this.get('guid'));
    },

    _rescaleImages: function _rescaleImages() {
      var $images = this.get('employees');

      $images.height('auto');
      $images.each(function (k, v) {
        var $el = _ember['default'].$(v);
        var height = $el.height();
        $el.height(Math.ceil(height));
      });
    }
  });
});