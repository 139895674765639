define('debd-2016-frontend/helpers/last-column', ['exports', 'ember'], function (exports, _ember) {
  exports.lastColumn = lastColumn;

  function lastColumn(params) {
    var lastClass = '';
    var row = 0;

    var widths = {
      '1-1': 1,
      '1-2': 1 / 2,
      '1-3': 1 / 3,
      '2-3': 2 / 3,
      '1-4': 1 / 4,
      '3-4': 3 / 4
    };

    if (_ember['default'].typeOf(params[1]) === 'array') {
      params[1].forEach(function (v, i) {
        if (i <= params[0]) {
          row += widths[v.column];
        }
      });
    }

    if (row % 1 === 0) {
      lastClass = 'last';
    }

    return lastClass;
  }

  exports['default'] = _ember['default'].Helper.helper(lastColumn);
});