define('debd-2016-frontend/mixins/page-transition', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    retried: false,

    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.get('retried')) {
          this.set('retried', false);
        } else {

          // stop transition
          transition.abort();

          // start fadeOut animation
          _ember['default'].$('main').css({
            'opacity': 0
          });

          // retry transition
          // todo: check why transitonEnd doesn't work well
          _ember['default'].run.later(this, function () {
            _this.set('retried', true);
            transition.retry();
          }, 200);
        }

        return true;
      },

      didTransition: function didTransition() {

        window.scrollTo(0, 0);

        // start fadeIn animation
        _ember['default'].$('main').css({
          'opacity': 1
        });

        return true;
      }
    }
  });
});