define("debd-2016-frontend/templates/components/page-projects-full", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 24
              },
              "end": {
                "line": 9,
                "column": 24
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/page-projects-full.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("strong");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "projectClient", ["loc", [null, [8, 36], [8, 53]]]]],
          locals: ["projectClient"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 20
              },
              "end": {
                "line": 13,
                "column": 75
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/page-projects-full.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Projekt ansehen");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 16,
              "column": 8
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/page-projects-full.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "project");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "content-container");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createAttrMorph(element1, 'style');
          morphs[3] = dom.createAttrMorph(element2, 'style');
          morphs[4] = dom.createMorphAt(element2, 1, 1);
          morphs[5] = dom.createMorphAt(element2, 3, 3);
          morphs[6] = dom.createAttrMorph(element3, 'style');
          morphs[7] = dom.createUnsafeMorphAt(element3, 0, 0);
          morphs[8] = dom.createMorphAt(element1, 5, 5);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["background-image: url('", ["get", "project.acf.background-image", ["loc", [null, [4, 65], [4, 93]]]], "');"]]], ["element", "action", ["goToProject", ["get", "project.post_name", ["loc", [null, [4, 123], [4, 140]]]]], [], ["loc", [null, [4, 100], [4, 142]]]], ["attribute", "style", ["concat", ["color: ", ["get", "project.acf.background-text-color", ["loc", [null, [5, 63], [5, 96]]]], ";"]]], ["attribute", "style", ["concat", ["color: ", ["get", "project.acf.background-text-color", ["loc", [null, [6, 40], [6, 73]]]], ";"]]], ["block", "each", [["get", "project.projectClients", ["loc", [null, [7, 32], [7, 54]]]]], [], 0, null, ["loc", [null, [7, 24], [9, 33]]]], ["inline", "join-array", [["get", "project.acf.main-category", ["loc", [null, [10, 37], [10, 62]]]], "name"], [], ["loc", [null, [10, 24], [10, 71]]]], ["attribute", "style", ["concat", ["color: ", ["get", "project.acf.background-text-color", ["loc", [null, [12, 40], [12, 73]]]], ";"]]], ["content", "project.acf.title-nice", ["loc", [null, [12, 78], [12, 106]]]], ["block", "link-to", ["project", ["get", "project.post_name", ["loc", [null, [13, 41], [13, 58]]]]], [], 1, null, ["loc", [null, [13, 20], [13, 87]]]]],
        locals: ["project"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "debd-2016-frontend/templates/components/page-projects-full.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-projects");
        dom.setAttribute(el1, "data-layout", "full");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "background-container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "content.projects", ["loc", [null, [3, 16], [3, 32]]]]], [], 0, null, ["loc", [null, [3, 8], [16, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});