define('debd-2016-frontend/components/project-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    _guid: null,
    _projectHeaderHeight: 0,
    _projectHeaderHeadline: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._guid = _ember['default'].guidFor(this);
      this._projectHeaderHeight = this.$().height();
      this._projectHeaderHeadline = this.$().find('h1').get(0);

      _ember['default'].$(window).on('scroll.' + this._guid, function () {
        requestAnimationFrame(function () {
          _this._parallax.apply(_this);
        });
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._projectHeaderHeight = 0;
      this._projectHeaderHeadline = null;

      _ember['default'].$(window).off('scroll.' + this._guid);
    },

    _parallax: function _parallax() {
      var windowScrollTop = _ember['default'].$(window).scrollTop();
      var parallax = Math.round(windowScrollTop * 0.4);
      var opacity = Math.round((1 - windowScrollTop / this._pageHeaderHeight) * 100) / 100;

      this._projectHeaderHeadline.style.opacity = opacity;
      this._projectHeaderHeadline.style.transform = 'translate3d(0,' + parallax + 'px,0)';
    }
  });
});