define('debd-2016-frontend/helpers/employee-image', ['exports', 'ember'], function (exports, _ember) {
  exports.employeeImage = employeeImage;

  function employeeImage(params) {
    var src;

    if (params[0] === 'horizontal') {
      src = params[1]['team-image-horizontal'];
    } else if (params[0] === 'vertical') {
      src = params[1]['team-image-vertical'];
    }

    return src;
  }

  exports['default'] = _ember['default'].Helper.helper(employeeImage);
});