define('debd-2016-frontend/components/page-projects-full', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    guid: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('guid', _ember['default'].guidFor(this));

      _ember['default'].$(window).on('scroll.' + this.get('guid'), function () {
        requestAnimationFrame(function () {
          _this._fadeProjectTitle.apply(_this);
        });
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).off('scroll.' + this.get('guid'));
    },

    _fadeProjectTitle: function _fadeProjectTitle() {
      this.$().find('.project').each(function (i, el) {
        var scroll = _ember['default'].$(window).scrollTop() - _ember['default'].$(el).offset().top;
        var percent = scroll / _ember['default'].$(window).height() * -100;

        if (percent <= 60) {
          _ember['default'].$(el).addClass('in-viewport');
        } else {
          _ember['default'].$(el).removeClass('in-viewport');
        }
      });
    },

    actions: {
      goToProject: function goToProject(slug) {
        this.get('router').transitionTo('project', slug);
      }
    }
  });
});