define("debd-2016-frontend/templates/components/website-header", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 12
            },
            "end": {
              "line": 25,
              "column": 12
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "id", "logo");
          dom.setAttribute(el1, "data-name", "logo");
          dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
          dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
          dom.setAttribute(el1, "viewBox", "0 0 56.126 6.5215");
          var el2 = dom.createElement("defs");
          var el3 = dom.createElement("style");
          var el4 = dom.createTextNode(".cls-1{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{fill:#181715;}");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("clipPath");
          dom.setAttribute(el3, "id", "clip-path");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          var el4 = dom.createElement("rect");
          dom.setAttribute(el4, "class", "cls-1");
          dom.setAttribute(el4, "y", "-0.00032");
          dom.setAttribute(el4, "width", "56.126");
          dom.setAttribute(el4, "height", "6.52");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("g");
          dom.setAttribute(el2, "class", "cls-2");
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "class", "cls-3");
          dom.setAttribute(el3, "d", "M2.394,1.155H1.407V5.366h.987c1.239,0,1.789-.839,1.789-2.106s-.55-2.105-1.789-2.105M0,0H2.431A2.95258,2.95258,0,0,1,5.626,3.26,2.95288,2.95288,0,0,1,2.431,6.521H0Z");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("polygon");
          dom.setAttribute(el2, "class", "cls-3");
          dom.setAttribute(el2, "points", "6.562 0 6.562 6.52 11.052 6.52 11.052 5.366 7.941 5.366 7.941 3.745 10.791 3.745 10.791 2.589 7.941 2.589 7.941 1.155 11.005 1.155 11.005 0 6.562 0");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("g");
          dom.setAttribute(el2, "class", "cls-2");
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "class", "cls-3");
          dom.setAttribute(el3, "d", "M12.2142,0h1.453s2.003,3.242,2.646,4.285h.028V0h1.322V6.521h-1.453s-1.956-3.177-2.645-4.285h-.028V6.521h-1.323Z");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "class", "cls-3");
          dom.setAttribute(el3, "d", "M18.8259,0h1.453s2.003,3.242,2.645,4.285h.029V0h1.322V6.521h-1.453s-1.956-3.177-2.645-4.285h-.028V6.521h-1.323Z");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("polygon");
          dom.setAttribute(el2, "class", "cls-3");
          dom.setAttribute(el2, "points", "25.438 0 25.438 6.52 29.928 6.52 29.928 5.366 26.817 5.366 26.817 3.745 29.667 3.745 29.667 2.589 26.817 2.589 26.817 1.155 29.881 1.155 29.881 0 25.438 0");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("g");
          dom.setAttribute(el2, "class", "cls-2");
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "class", "cls-3");
          dom.setAttribute(el3, "d", "M33.3167,1.15518h-.839v1.761h.839c.81,0,1.22-.261,1.22-.923,0-.633-.41-.838-1.22-.838m1.416,2.692,1.499,2.674h-1.536l-1.351-2.468h-.867v2.468h-1.388V.00018h2.385c1.509,0,2.496.596,2.496,1.984a1.84247,1.84247,0,0,1-1.238,1.863");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("polygon");
          dom.setAttribute(el2, "class", "cls-3");
          dom.setAttribute(el2, "points", "37.054 0 37.054 6.52 41.302 6.52 41.302 5.357 38.452 5.357 38.452 0 37.054 0");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("polygon");
          dom.setAttribute(el2, "class", "cls-3");
          dom.setAttribute(el2, "points", "42.464 0 42.464 6.52 46.954 6.52 46.954 5.366 43.843 5.366 43.843 3.745 46.694 3.745 46.694 2.589 43.843 2.589 43.843 1.155 46.908 1.155 46.908 0 42.464 0");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("rect");
          dom.setAttribute(el2, "class", "cls-3");
          dom.setAttribute(el2, "x", "48.117");
          dom.setAttribute(el2, "width", "1.397");
          dom.setAttribute(el2, "height", "6.52");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("g");
          dom.setAttribute(el2, "class", "cls-2");
          var el3 = dom.createElement("path");
          dom.setAttribute(el3, "class", "cls-3");
          dom.setAttribute(el3, "d", "M50.676,0h1.453s2.003,3.242,2.646,4.285h.027V0h1.324V6.521H54.673s-1.957-3.177-2.646-4.285h-.028V6.521H50.676Z");
          dom.setAttribute(el3, "transform", "translate(0 0.00032)");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 20
              },
              "end": {
                "line": 29,
                "column": 43
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Home");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 16
            },
            "end": {
              "line": 30,
              "column": 16
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["home"], [], 0, null, ["loc", [null, [29, 20], [29, 55]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 20
              },
              "end": {
                "line": 32,
                "column": 51
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Projekte");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 16
            },
            "end": {
              "line": 33,
              "column": 16
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["projects"], [], 0, null, ["loc", [null, [32, 20], [32, 63]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 20
              },
              "end": {
                "line": 35,
                "column": 43
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Team");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 16
            },
            "end": {
              "line": 36,
              "column": 16
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["team"], [], 0, null, ["loc", [null, [35, 20], [35, 55]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 20
              },
              "end": {
                "line": 38,
                "column": 49
              }
            },
            "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Kontakt");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 16
            },
            "end": {
              "line": 39,
              "column": 16
            }
          },
          "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["contact"], [], 0, null, ["loc", [null, [38, 20], [38, 61]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 9
          }
        },
        "moduleName": "debd-2016-frontend/templates/components/website-header.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "omt-banner");
        dom.setAttribute(el1, "class", "background-container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "omt-banner");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "omt-banner__image-container");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "omt-banner__content-container");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "omt-banner__text-container");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("strong");
        var el6 = dom.createTextNode("onemillionthanks.org");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("1 Euro geben. Mit einer Zeichnung Danke sagen und Gutes tun.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "https://onemillionthanks.org");
        var el6 = dom.createTextNode("Jetzt mitmachen!");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "omt-banner__button-container");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "button__omt");
        dom.setAttribute(el5, "href", "https://onemillionthanks.org");
        var el6 = dom.createTextNode("Jetzt mitmachen!");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "omt-banner__close");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5, "src", "/images/close.png");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("header");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "background-container");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "content-container");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3, 5, 1]);
        var element1 = dom.childAt(fragment, [2, 1, 1]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 2, 2);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(element2, 4, 4);
        return morphs;
      },
      statements: [["element", "action", ["clickBanner"], [], ["loc", [null, [15, 20], [15, 44]]]], ["block", "link-to", ["home"], [], 0, null, ["loc", [null, [23, 12], [25, 24]]]], ["block", "active-link", [], [], 1, null, ["loc", [null, [28, 16], [30, 32]]]], ["block", "active-link", [], [], 2, null, ["loc", [null, [31, 16], [33, 32]]]], ["block", "active-link", [], [], 3, null, ["loc", [null, [34, 16], [36, 32]]]], ["block", "active-link", [], [], 4, null, ["loc", [null, [37, 16], [39, 32]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});