define('debd-2016-frontend/routes/project', ['exports', 'ember', 'debd-2016-frontend/mixins/page-transition', 'debd-2016-frontend/config/environment'], function (exports, _ember, _debd2016FrontendMixinsPageTransition, _debd2016FrontendConfigEnvironment) {
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend(_debd2016FrontendMixinsPageTransition['default'], {
    headData: _ember['default'].inject.service(),

    model: function model(params) {
      return this.store.query('project', { 'slug': params.slug });
    },

    afterModel: function afterModel(model) {
      var title = '';

      // model returns array, so fetch the first one
      model.forEach(function (project) {
        title = project.get('title');
      });

      set(this, 'headData.title', title + _debd2016FrontendConfigEnvironment['default'].basicPageTitle);
      set(this, 'headData.description', model.get('acf.page-description'));
      set(this, 'headData.image', model.get('acf.page-image'));
    },

    actions: {
      error: function error(_error) {
        if (_error) {
          return this.transitionTo('error');
        }
      }
    }
  });
});