define('debd-2016-frontend/components/project-slider', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    el: null,
    flickity: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var el = this.$().find('.project-slider');

      if (el.length > 0) {
        this.set('el', el);

        var flickity = new Flickity(el.get(0), {
          adaptiveHeight: true,
          imagesLoaded: true,
          cellAlign: 'left',
          wrapAround: true,
          pageDots: false,
          arrowShape: 'M64.3,100 14.3,50 64.3,0 65.7,1.4 17.1,50 65.7,98.6 z'
        });

        this.set('flickity', flickity);
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('flickity').destroy();
    }
  });
});
/* global Flickity */