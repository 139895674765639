define('debd-2016-frontend/helpers/join-array', ['exports', 'ember'], function (exports, _ember) {
  exports.joinArray = joinArray;

  function joinArray(params) {
    var str = '';

    if (_ember['default'].typeOf(params[0]) === 'array') {
      params[0].forEach(function (v, i) {
        str += v.name;
        if (i < params[0].length && i !== params[0].length - 1) {
          str += ' and ';
        }
      });
    }

    return str;
  }

  exports['default'] = _ember['default'].Helper.helper(joinArray);
});