define('debd-2016-frontend/router', ['exports', 'ember', 'debd-2016-frontend/config/environment'], function (exports, _ember, _debd2016FrontendConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _debd2016FrontendConfigEnvironment['default'].locationType,
    metrics: _ember['default'].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _ember['default'].get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('home', { path: '/' });
    this.route('projects', { path: '/projects' });
    this.route('project', { path: '/projects/:slug' });
    this.route('team', { path: '/team' });
    this.route('contact', { path: '/contact' });
    this.route('imprint', { path: '/imprint' });
    this.route('data-protection', { path: '/data-protection' });
    this.route('error', { path: '/*path' });
  });

  exports['default'] = Router;
});