define('debd-2016-frontend/components/website-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    guid: null,

    actions: {
      clickBanner: function clickBanner() {
        var banner = document.getElementById('omt-banner');
        banner.style.display = 'none';
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('guid', _ember['default'].guidFor(this));

      _ember['default'].$(window).on('scroll.' + this.get('guid'), function () {
        _this._setNavigation.apply(_this);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).off('scroll.' + this.get('guid'));
    },

    _setNavigation: function _setNavigation() {
      var windowScrollTop = _ember['default'].$(window).scrollTop();

      if (windowScrollTop > 50) {
        _ember['default'].$('html').addClass('scrolled');
      } else {
        _ember['default'].$('html').removeClass('scrolled');
      }
    }
  });
});