define("debd-2016-frontend/templates/components/project-header", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "debd-2016-frontend/templates/components/project-header.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "project-header content-container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "headline");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "font-size-h1 font-size-h1--with-childs");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createAttrMorph(element1, 'style');
        morphs[2] = dom.createUnsafeMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["attribute", "style", ["concat", ["background-image: url('", ["get", "content.acf.background-image", ["loc", [null, [1, 78], [1, 106]]]], "'); background-size: ", ["get", "content.acf.background-image-size", ["loc", [null, [1, 131], [1, 164]]]], "; background-position: ", ["get", "content.acf.background-image-position", ["loc", [null, [1, 191], [1, 228]]]], ";"]]], ["attribute", "style", ["concat", ["color: ", ["get", "content.acf.background-text-color", ["loc", [null, [3, 75], [3, 108]]]], ";"]]], ["content", "content.acf.background-title", ["loc", [null, [3, 113], [3, 147]]]]],
      locals: [],
      templates: []
    };
  })());
});