define('debd-2016-frontend/components/page-jobs-accordion', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    jobTitles: null,
    jobs: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('jobTitles', this.$().find('.job-title'));
      this.set('jobs', this.$().find('.jobs-wrapper'));

      // get query parameter and open job
      var self = this;
      _ember['default'].$('body').imagesLoaded(function () {
        var job = self._getParameterByName('job');
        if (job) {

          if (job === 'all') {

            self.get('jobTitles').addClass('open');
            var $alljob = self.get('jobs').filter(function () {
              return _ember['default'].$(this).data('job') === 'all';
            });

            var alloffset = $alljob.offset().top;
            _ember['default'].$('html, body').animate({ scrollTop: alloffset }, 500);
          } else {

            self.get('jobTitles').removeClass('open');
            var $job = self.get('jobTitles').filter(function () {
              return _ember['default'].$(this).data('job') === job;
            });

            $job.addClass('open');
            var offset = $job.offset().top;
            _ember['default'].$('html, body').animate({ scrollTop: offset }, 500);
          }
        }
      });

      // handle events
      this.get('jobTitles').on('click', function () {
        _ember['default'].$(this).toggleClass('open');
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('jobTitles').off('click');
    },

    _getParameterByName: function _getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
  });
});